import React, { useEffect } from 'react';
import { Root, Tagged } from './style';

import { useState } from '@hookstate/core';
import chunk from 'lodash/chunk';

import Layout from 'components/Layout/Dashboard';
import FlipBox from 'components/Common/FlipBox';

import { formatHODL, formatNumber, inWindow, useSiteData } from 'utils';

import { useWeb3, getBalance, getBalanceBNB } from 'web3';

import link from 'assets/Dashboard/link.svg';

import vars from 'variables';

const DashboardAddresses = ({ location }) => {
  const { projectWallets } = useSiteData().resources;

  const { bsc } = useWeb3();

  const sortOrder = (tag) => {
    if (tag === 'legacy') {
      return 99999;
    }

    let order = [
      'project',
      'hodl',
      'hodlx',
      'cex',
      'dex',
      'nfts',
      'hub',
    ].indexOf(tag);

    return order === -1 ? 999 : order;
  };

  const tags = Array.from(
    new Set(projectWallets.map(({ tags }) => tags.split(/\s*,\s*/)).flat())
  ).sort((a, b) => sortOrder(a) - sortOrder(b));

  const wallets = useState([]);

  const parse = (string) => parseFloat(string || '0');

  const format = (currency, amount) =>
    (currency === 'hodl' || currency === 'hodlx' ? formatHODL : formatNumber)(
      amount
    );

  const toClass = (tag) =>
    tag.toLowerCase().replace('$', '').replace(/\s/g, '-');

  const Tags = Tagged(tags);

  useEffect(() => {
    const updateWallets = async () => {
      const updatedWallets = await Promise.all(
        projectWallets.map(async (wallet) => {
          const tokenBalance = await getBalance(wallet.address);
          const balance = await getBalanceBNB(wallet.address);
          return {
            ...wallet,
            tags: wallet.tags ? wallet.tags.split(/\s*,\s*/) : [], // Falls tags null ist, leeres Array setzen
            balances: Object.entries({
              ...(wallet.trackBNB ? { bnb: balance } : {}),
              ...(wallet.trackHODL ? { hodl: tokenBalance } : {}),
              ...(wallet.trackBUSD ? { busd: 0 } : {}),
            }),
          };
        })
      );

      wallets.set(updatedWallets);
    };
    updateWallets();
  }, []);

  return (
    <Layout location={location}>
      {inWindow() ? (
        <Root>
          <Tags
            className={`tags ${tags
              .map(toClass)
              .filter((tag) => tag !== 'legacy')
              .join(' ')}`}
          >
            <ul>
              {tags.map((tag) => (
                <li
                  key={toClass(tag)}
                  className={toClass(tag)}
                  onClick={(e) =>
                    e.target.closest('.tags').classList.toggle(toClass(tag))
                  }
                >
                  {tag}
                </li>
              ))}
            </ul>
            {wallets.get().map((wallet) => (
              <FlipBox
                key={wallet.address}
                cssClass={wallet.tags.map(toClass).join(' ')}
              >
                <div className="wallet">
                  <strong>{wallet.name}</strong>
                  {wallet.balances.map(([currency, amount]) => (
                    <small key={currency}>
                      {format(currency, amount)}{' '}
                      {currency.includes('hodl') ? '$' : ''}
                      {currency.toUpperCase()} -{' '}
                      <label>
                        $
                        {currency.toLowerCase() === 'busd'
                          ? formatNumber(amount, 2)
                          : formatNumber(
                              amount * bsc[currency.toLowerCase() + 'UsdPrice'],
                              2
                            )}
                      </label>
                    </small>
                  ))}
                  <a
                    target="blank"
                    href={`https://bscscan.com/address/${wallet.address}`}
                  >
                    <span className="desktop">
                      {wallet.address.slice(0, 18)}...
                      {wallet.address.slice(-18)}
                    </span>
                    <span className="mobile">
                      {wallet.address.slice(0, 15)}...
                      {wallet.address.slice(-15)}
                    </span>
                    <img alt="bscscan" src={link} />
                  </a>
                </div>
                <div className="wallet">
                  <p>{wallet.description}</p>
                </div>
              </FlipBox>
            ))}
          </Tags>
        </Root>
      ) : (
        ''
      )}
    </Layout>
  );
};

export default DashboardAddresses;
